import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import Button from '~components/Button'
import resolveLink from '../utils/resolveLink'
import { Link } from 'gatsby'

const FeaturedArticle = ({ className, content, reflect}) => {
	const [hover, setHover] = useState(false)
	return (
		<Wrap className={className}>
			<ImageCol 
				css={css`
					grid-column: ${reflect ? '7/13' : '1/7'};
					${mobile}{
						grid-column: span 6;
					}
			`}>
				<LinkWrapper 
					onMouseEnter={()=> setHover(true)}
					onMouseLeave={()=> setHover(false)}
					to={resolveLink(content)}
				>
					<Thumb 
						image={content?.content?.thumb} 
						aspectRatio={0.72}
						css={css`
							img{transform: ${hover ? 'scale3d(1.02, 1.02, 1)' : 'scale3d(1, 1, 1)'};}
						`}
					/>
				</LinkWrapper>
			</ImageCol>
			<Content 
				css={css`
					grid-column: ${reflect ? '2/6' : '8/12'};
					${mobile}{
						grid-column: span 6;
					}
			`}>
				<Top>
					<Meta>
						<span>
							{content?.content?.topics?.map(topic => (
								topic.title
							)).join(', ')}
						</span> ● <span className='h3 numbers'>{content?.datePublished}</span>
					</Meta>
					<Title className='h2' to={resolveLink(content)}> {content?.title}</Title>
				</Top>
				<Bottom>
					<Excerpt>{content?.content?.excerpt}</Excerpt>
					<Button 
						className='h3' 
						link={
							{
								...content, 
								linkType: 'internal', 
								document: {
									_type: 'article',
									slug: {
										current: content?.slug?.current
									}
								},
							}}
					>Read More</Button>
				</Bottom>
			</Content>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: var(--xxxl);
	padding-bottom: var(--xxxl);
	max-height: 100vh;
	${mobile}{
		padding-top: 0;
		padding-bottom: var(--l);
		max-height: none;
	}
`
const ImageCol = styled.div`
	grid-row: 1;
	${mobile}{
		margin-left: -20px;
		margin-right: -20px;
	}
`
const LinkWrapper = styled(Link)`
	overflow: hidden;
	object-position: center;
	margin: 0 auto;
	max-width: 50.9vh;
	min-width: 332px;
	display: block;
	> div {
		max-width: 50.9vh;
		min-width: 332px;
	}
	${mobile}{
		max-width: none;
		min-width: auto;
		> div {
			max-width: none;
			min-width: auto;
		}
	}
`
const Thumb = styled(Image)`
	img{transition: transform 0.3s;}
`
const Content = styled.div`
	padding-top: var(--xl);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	grid-row: 1;
	${mobile}{
		grid-row: 2;
	}
`
const Top = styled.div`
	text-align: center;
`
const Bottom = styled.div`
	text-align: center;
	margin-bottom: var(--s);
`
const Meta = styled.h3`
	margin-bottom: var(--xl);
`
const Title = styled(Link)`
	margin-bottom: var(--m);
	padding: 0 5%;
`
const Excerpt = styled.p`
	margin-bottom: var(--m);
`

FeaturedArticle.propTypes = {
	className: PropTypes.string,
	content: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	reflect: PropTypes.bool,
}

export default FeaturedArticle